<template>
    <modal :open="modalOpen" @close="$emit('closeModal')" :loading="modalLoading" :modalData="modalData">
        <template v-slot:modalContent>
            <v-row class="pa-0 ma-0">
                <v-col cols="6">
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">id:</span>{{ item.id }}
                    </div>
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">Table:</span>{{ item.auditable_type || '-' }}
                    </div>
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">Event:</span>{{ item.event || '-' }}
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">Url:</span>
                        <v-tooltip top v-if="item.url">
                            <template v-slot:activator="{ on, attrs }">
                                <a target="_blank" :href="item.url"  v-bind="attrs" v-on="on">Catchup URL</a>
                            </template>
                            <span>{{item.url}}</span>
                        </v-tooltip>
                        <span v-else> - </span>
                    </div>
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">IP Address:</span>{{ item.ip_address || '-' }}
                    </div>
                    <div class="text-capitalize pa-2">
                        <span class="font-weight-bold mr-3">User Agent:</span>{{ item.user_agent || '-' }}
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="3">
                        <v-data-table :loading="modalLoading" :headers="tableHeaders" :items="tableItems" ></v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </modal>
</template>

<script>
import _ from 'lodash';

export default {
    props:['modalOpen', 'item'],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data: (vm) => ({
        modalLoading: false,
        modalData: {
            title: 'View Log',
            width: 1200,
            height: '600px'
        },
        tableHeaders: [
            { text: 'Column',  value: "title"},
            { text: "Old Value", value: "old_value" },
            { text: "New Value", value: "new_value" },
        ],
        tableItems: []
    }),
    created() {
        this.modalLoading = true;
        this.getTableItems();
    },
    methods: {
        async getTableItems() {
            if (this.item && this.item.new_values) {
                const newValue = JSON.parse(JSON.stringify(this.item.new_values)),
                    oldValue = this.item.old_values ? JSON.parse(JSON.stringify(this.item.old_values)) : null;
                await Object.keys(newValue).map(key => {
                    this.tableItems.push({title : key, new_value: newValue[key], old_value: oldValue ? oldValue[key] : ''});
                })
            }
            this.modalLoading = false;
        },
        savePrefernce() {
            this.$emit('closeModal');
        },
    }
}
</script>